<template>
  <CContainer class="d-flex align-items-center min-vh-100">
    <CRow class="w-100 justify-content-center">
      <CCol class="text-center">
        <div class="w-100">
          <div class="clearfix">
            <h1 class="display-3 mr-4">404 Not Found</h1>
            <h4 class="pt-3">Oups, vous ne devriez pas être là</h4>
            <p class="text-muted">La page que vous cherchez n'existe pas ou plus.</p>
          </div>
        </div>
        <CRow class="mt-4">
          <CCol class="text-center sm-6">
            <CButton
              color="outline-dark"
              shape="pill"
              block class="px-4"
              size="lg"
              @click="$router.push('/')">
              Retour à l'accueil
            </CButton>
          </CCol>
          <CCol class="text-center sm-6">
            <CButton
              color="outline-dark"
              shape="pill"
              block class="px-4"
              size="lg"
              @click="$router.push('/pages/login')">
              Se connecter
            </CButton>
          </CCol>
        </CRow>
      </CCol>
    </CRow>

  </CContainer>
</template>

<script>
export default {
  name: 'Page404'
}
</script>
